import IMenu from "~/interfaces/IMenu";
import { listMenu } from "~/constants/menu";
import { permissionView } from "~/constants/permission";

function extractModuleName(to: any): string {
  const menus = flattenMenus(listMenu())
  const storeId = to.params?.store_id;
  const warehouseId = to.params?.warehouse_id;

  for (let menu of menus) {
    // Gantikan placeholder di link berdasarkan param yang tersedia
    let linkPath = menu.link;
    if (linkPath != '/') {
      if (storeId) {
        linkPath = linkPath.replace("{storeId}", storeId);
      }
      if (warehouseId) {
        linkPath = linkPath.replace("{warehouseId}", warehouseId);
      }

      if (to.path.includes(linkPath)) {
        return menu.module;
      }
    }
  }

  return 'dashboard';
}

export default defineNuxtRouteMiddleware((to, from) => {
  const nuxt = useNuxtApp()

  // Check if user has the access to the targeted route
  const moduleName = extractModuleName(to)

  // Excluded urls
  const excludedRouteNames = ['dashboard', '/']

  if (excludedRouteNames.indexOf(moduleName) === -1 && !nuxt.$hasAccess(`${permissionView} - ${moduleName}`)) {
    nuxt.$notify(false, nuxt.$getTranslation('shared.notifications.unauthorized'))
    return navigateTo('/')
    // return abortNavigation()
  }
})

function flattenMenus(menus: IMenu[]): IMenu[] {
  const result: IMenu[] = [];

  function extractItems(menuItems: IMenu[]): void {
    menuItems.forEach(item => {
      if (item.link) {
        result.push({
          title: item.title,
          icon: item.icon,
          link: item.link,
          module: item.module
        });
      }
      if (item.children) {
        extractItems(item.children);
      }
    });
  }

  extractItems(menus);
  return result;
}