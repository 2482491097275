export const permissionView = 'view'
export const permissionCreate = 'create'
export const permissionUpdate = 'update'
export const permissionDelete = 'delete'
export const permissionCancel = 'cancel'
export const permissionReturn = 'return'
export const permissionViewHistoryItem = 'view history item'
export const permissionAddPayment = 'add payment'
export const permissionPrintInvoice = 'print invoice'
export const permissionPrintDeliveryOrder = 'print delivery order'
export const permissionReceiveItem = 'receive item'
export const permissionFilterDate = 'filter date'
export const permissionFilterYear = 'filter year'
export const permissionSendNotification = 'send notification'
export const permissionProcess = 'process'